import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://732911a488c694ab3cd2fc9aef6931ea@o702792.ingest.us.sentry.io/4506973153001472",
  debug: false,
  environment: "production",
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 0.1,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});